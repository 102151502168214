import {defineComponent, onMounted, reactive, ref, toRefs} from 'vue';
import {loginController} from "@/service/LoginController";
import emailTip from "./Email";
import useValid from "@/utils/valid";
import InitSignInDto, {AdminSettingRegCoupon} from "@/service/model/login/InitSignInDto";;
import {getUrlParams} from "@/utils/displayUtil";

export default defineComponent({
    props: {
        popCallBack: {
            type: Function
        },
        isRegPage: Boolean,
    },
    setup(props,content) {
        const registerForm = reactive({
            email: <string> '',
            password: <string> '',
            firstName:<string> '',
            lastName:<string> '',
            validateCode:undefined,
            repeatPassword : <string> '',
            newsletter : <boolean> true
        });
        const {errors, validField,valid,validRules} = useValid(registerForm)
        const vueEmailRef = ref(null)
        const state = reactive({
            regEmail: <string []> [],
            visibleEmailBox: <boolean> false,
            regEmailChoose: <number> 0,
            score: <number> 0,
            validateImg: <string> '/bff/validateCode.jpeg',
            showRegisterVerify: <boolean> false,
            regCouponPrompt: <any> null,
            regCoupon:<AdminSettingRegCoupon[]>[],
            init:false,
            isRegistering:false,
        })
        onMounted(async () => {
            let params = getUrlParams('access');
           /* // 是否展示验证码
            if(params){
                return;
            }*/

            let initSignInDto: InitSignInDto = await loginController.initSignIn("1");
            state.showRegisterVerify = initSignInDto.isRegValidateCodeDisplay;
            state.regCouponPrompt = initSignInDto.regCouponPrompt;
            state.regCoupon = initSignInDto.regCoupon;
            state.init= true;
                //点击下拉框的地方，收起
            document.addEventListener('click', (e) => {
                if (state.visibleEmailBox && !vueEmailRef.value.contains(e.target)){
                    state.visibleEmailBox = false;
                }
            }, false)
        })
        // 切换验证码
        const changeValidateCode = () =>{
            state.validateImg = '/bff/validateCode.jpeg?r=' + Math.random();
        }

        const toSign = () => {
            content.emit('toSign')
        }

        // 注册
        const register = async (value,option) => {
            state.isRegistering = true;
            if(!valid()) {
                return false;
            }
            try {
                // @ts-ignore
                await loginController.register(registerForm);
                state.isRegistering = false;
                // 判断是否弹窗注册
                if(props.popCallBack) {
                    // @ts-ignore
                    props.popCallBack()
                } else {
                    // 跳转注册成功页面
                    window.location.href='/Register-successfully.html';
                }
            }catch (e){
                state.isRegistering = false;
                let errData = e.response.data;
                // 刷新验证码
                if(state.showRegisterVerify) {
                    changeValidateCode();
                }
                if(errData.err_code=="510") {
                    if (errData.message == "ERROR_CUSTOMER_EMAIL_NOT_EXISTS") {
                        errors["email"]='login.email.notexists'.translate();
                    } else if (errData.message == "ERROR_CUSTOMER_EMAIL_EXISTS"){
                        errors["email"]='login.email.exists'.translate();
                    } else if (errData.message == 'ERROR_CUSTOMER_LOCKED') {
                        errors["email"]='login.email.locked'.translate();
                    } else if (errData.message == "ERROR_VERIFYNUMBER") {
                        errors["validateCode"]='login.validatecode.error'.translate();
                    }
                }
                registerForm.password = ""
                registerForm.repeatPassword = ""
            }
        }

        // 邮箱联想
        const emailSmartMatch = (email) => {
            let index = email.indexOf('@');
            if(index == -1 || index == email.length-1) {
                state.regEmail = emailTip.filter((item,i)=>i<10).map((item,i, )=> {
                    return index == -1 ? email +'@'+ item: email + item;
                });
            } else {
                let substr = email.substr(index+1),
                    prefix = email.substr(0,index+1);
                state.regEmail = emailTip.map((item, i)=>{
                    if(item.startsWith(substr)) return prefix +item;
                }).filter((item,i)=>i<10);
            }

            if(state.regEmail.length > 0) {
                state.visibleEmailBox = true;
            }
        }
        // 密码安全等级
        const passGrade = (pwd) => {
            if (pwd == "") {
                state.score = 0;
                return;
            }
            state.score = parseInt(String(gradePassword(pwd)));
        }

        // 邮箱强弱得分计算
        const gradePassword = (pwd) => {
            /*-- 计算密码强弱得分 --*/
            let symbol = "!\"#$%&'()*+,-.<=>?[\\]^_`{|}~";
            let bigLetter = /[a-z]/;
            let smallLetter = /[A-Z]/;
            let number = "0123456789";
            let score = 0;
            let symbol2 = 0;
            let bigLetter2 = 0;
            let smallLetter2 = 0;
            let number2 = 0;
            if (pwd != null) {
                //长度分
                if (pwd.length >= 6 && pwd.length <= 8) {
                    score += 5;
                } else if (pwd.length >= 9 && pwd.length <= 13) {
                    score += 10;
                } else if (pwd.length >= 14 && pwd.length <= 20) {
                    score += 25;
                }
                for (var i = 0; i < pwd.length; i++) {
                    var key = pwd[i];
                    if (symbol.indexOf(key) > 0) {
                        symbol2 += 1;
                    } else if (key.match(bigLetter)) {
                        bigLetter2 += 1;
                    } else if (key.match(smallLetter)) {
                        smallLetter2 += 1;
                    } else if (number.indexOf(key)) {
                        number2 += 1;
                    }
                }
                //字母分
                if (bigLetter2 > 1 && smallLetter2 > 1) {
                    score += 20;
                } else if ((bigLetter2 > 0 && smallLetter2 <= 1) || (bigLetter2 <= 1 && smallLetter2 > 0)) {
                    score += 10;
                }
                //数字分
                if (number2 == 1) {
                    score += 10;
                } else if (number2 > 1) {
                    score += 20;
                }
                //符号分
                if (symbol2 == 1) {
                    score += 10;
                } else if (symbol2 > 1) {
                    score += 25;
                }
                //奖励分
                if (bigLetter2 > 0 || smallLetter2 > 0 && number2 > 0 && symbol2 == 0) {
                    score += 2;
                }
                if (bigLetter2 > 0 || smallLetter2 > 0 && number2 > 0 && symbol2 > 0) {
                    score += 3;
                }
                if (bigLetter2 > 0 && smallLetter2 > 0 && number2 > 0 && symbol2 > 0) {
                    score += 5;
                }
            }
            return score;
        }

        // 注册 邮箱下拉,键盘事件
        const emailDown = () => {
            if (state.regEmailChoose < state.regEmail.length - 1) {
                state.regEmailChoose = state.regEmailChoose + 1;
            }
        }
        const emailUp = () => {
            if (state.regEmailChoose > 0) {
                state.regEmailChoose = state.regEmailChoose - 1;
            }
        }
        const emailChoose = (email) => {
            if (email) {
                if (state.regEmail[state.regEmailChoose]) {
                    registerForm.email = state.regEmail[state.regEmailChoose];
                    state.regEmail = [];
                }
            }
        }

        return {
            ...toRefs(state),
            ...toRefs(registerForm),
            register,
            vueEmailRef,
            changeValidateCode,
            emailSmartMatch,
            passGrade,
            emailDown,
            emailUp,
            emailChoose,
            toSign,
            errors,
            validField
        }
    }

})