import axios from "axios";

export const itegtm = {
    getProducts:function(ids,func){
        axios.post('/gtm/getGtmProductsByIds',ids,{
            headers: {'Content-Type': 'application/json'}
            })
            .then(function (response) {
                func(response.data);
            })
            .catch(function (error) {
                console.error(error);
            });
    },
    //item_list_name:位置名字,item_list_id:位置id
    pushProducts:function (event,items,item_list_name,item_list_id,qty){
        if(!qty){
            qty=[1];
        }
        for(var index in items){
            var item = items[index];
            var quantity = Number(qty[index]==null?qty[0]:qty[index]);
            if(item["item_variant"]==null){
                delete item["item_variant"];
                
                
            }
            if(!item_list_name){
                item_list_name="Other";
                item_list_id="O001";
            }
            item["item_list_name"] = item_list_name;
            item["item_list_id"] = item_list_id;
            item["index"] = index+1;
            item["quantity"] = quantity;
        }
        dataLayer.push({ecommerce: null});
        dataLayer.push({
            'event': event,
            'ecommerce': {
                'items': items
            }
        });
    },
    browse:function ( pageType, customerId, productCodes = "", totalValue = "") {
        let data = {
            'event': "browse",
            'product_ids': productCodes,
            'page_type': pageType,
            'total_value': totalValue
        };
        if (customerId) {
            data['user_id'] = customerId;
        }
        dataLayer.push(data);
    },
    /**
     * V22.04 面包屑结构化代码
     * @param data
     */
    crumbStructured: function(...data){
        data.unshift({"name": "Home","item": window.location.hostname});
        for(var i =0 ;i< data.length;i++){
            var item =data[i];
            item["position"]=i+1;
            item["@type"]="ListItem";
        }
        var b = {"@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement":data};
        var script = document.createElement("script");  //创建一个script标签
        script.type = "application/ld+json";
        script.innerHTML=JSON.stringify(b);

        var obt = document.getElementsByTagName("head")[0];
        obt.appendChild(script);
    },
    getOrderInfo:function (orderId,func){
        axios.get("/gtm/getOrderInfoById",{
            params:{
                orderId:orderId
            }
            }).then(function(data){
                    func(data.data)
            })
    },
    addToCart:function (ids,item_list_name,item_list_id,qty){
        try{
            itegtm.getProducts(ids,function(items){itegtm.pushProducts("add_to_cart",items,item_list_name,item_list_id,qty)});
        }catch(e){
            console.log("itegtm is not defined");
        }
    },
    removeFromCart:function (ids,item_list_name,item_list_id,qty){
        try{
            itegtm.getProducts(ids,function(items){itegtm.pushProducts("remove_from_cart",items,item_list_name,item_list_id,qty)});
        }catch(e){
            console.log("itegtm is not defined");
        }
    },
    addToWishList:function (ids,item_list_name,item_list_id){
        try{
            itegtm.getProducts(ids,function(items){itegtm.pushProducts("add_to_wishlist",items,item_list_name,item_list_id)});
        }catch(e){
            console.log("itegtm is not defined");
        }
    },
    pushOrder:function(event,data){
        dataLayer.push({ecommerce: null});
        data.event = event;
        if(!data.ecommerce.coupon){
            delete data.ecommerce.coupon;
        }
        for(var item of data.ecommerce.items){
            if(item["item_variant"]==null){
                delete item["item_variant"];
            }
        }
        dataLayer.push(data);
    },
    order:function (orderId){
        itegtm.getOrderInfo(orderId,function (data){
            itegtm.pushOrder("order",data)}
        );
    },
    payment:function (orderId){
        itegtm.getOrderInfo(orderId,function (data){itegtm.pushOrder("payment",data)});
    },
    register:function (email){
        dataLayer.push({event:"register",email:email});
    },
    viewItem:function (id){
        try{
            itegtm.getProducts([id],function(items){itegtm.pushProducts("view_item",items,'Product','P001')});
        }catch(e){
            console.log("itegtm is not defined");
        }
    },
    viewCart:function (ids,qty){
        if(!ids || ids.length==0)return;
        try{
            itegtm.getProducts(ids,function(items){itegtm.pushProducts("view_cart",items,'Cart','C003',qty)});
        }catch(e){
            console.log("itegtm is not defined");
        }
    },
    //导航，搜索，类别列表
    viewItemList:function (ids,item_list_name,item_list_id){
        if(!ids || ids.length==0)return;
        try{
            itegtm.getProducts(ids,function(items){itegtm.pushProducts("view_item_list",items,item_list_name,item_list_id)});
        }catch(e){
            console.log("itegtm is not defined");
        }
    },
    search:function (keyword,isBigCustomer,count){
        if(!isBigCustomer){
            isBigCustomer = 0;
        }
        dataLayer.push({event:"search", search_term: keyword,isBigCustomer:isBigCustomer,count:count});
    },
};
