import { ref, nextTick, onMounted, defineComponent, PropType } from 'vue';
import {getScreen, PhScreen} from "@/utils/media";

export default defineComponent({
    name: 'AddToReplacementCart',
    props: {
        orderId: {
            type: String,
            required: true,
        },
        sign: {
            type: String,
            required: true,
        },
        tipMsg: {
            type: String,
            required: true,
        },
        dom: {
            type: Object as PropType<HTMLElement>,
            required: true,
        },
        div:{
            type: Object as PropType<HTMLElement>,
            required: true,
        },
        cancelCallback: {
            type: Function,
            required: true,
        },
    },
    setup(props) {
        const fade = ref(false);
        const setPosition = async (clickWrap, popWrap) => {
            await nextTick()
            if(getScreen()!= PhScreen.PHONE && getScreen()!= PhScreen.PHONE_SMALL) {
                let {width, height, top, left} = clickWrap.getBoundingClientRect()
                popWrap.style.position = "absolute"
                popWrap.style.zIndex = 99
                popWrap.style.top = top  + window.scrollY - popWrap.clientHeight - 6 + "px"
                popWrap.style.left = left + window.scrollX - ((popWrap.clientWidth- clickWrap.clientWidth)/2) - 65 + "px"
            } else {
                popWrap.style.position = "fixed"
                popWrap.style.zIndex = 99
                popWrap.style.top = '50%'
                popWrap.style.left = '25%'
            }
        }

        // 3秒后触发关闭
        const autoClose = () => {
            setTimeout(() => {
                props.cancelCallback();
            }, 3000);
        };

        onMounted(async () => {
            await setPosition(props.dom, props.div);
            autoClose();
        });

        return {
            fade,
        };
    },
});
