import {createApp, nextTick} from "vue"
import AddToCart from '@/components/common/addToCart/AddToCart.vue'
import BatchAddToCart from '@/components/common/batchAddToCart/BatchAddToCart.vue'
import AddToReplacementCart from "@/components/common/addToReplacementCart/AddToReplacementCart.vue"
import SelectReplacementDto from "@/service/model/replacement/SelectReplacementDto";

export function addToCart(pid:number,qty:number,dom,store,successCallback: Function = null):Promise<any>{
    let popDom = document.getElementsByClassName('pop-container')
    if(popDom.length > 0) {
        popDom.item(0).remove();
    }
    // 创建一个dom节点
    const div = document.createElement('div')
    div.setAttribute('class', 'pop-container')
    // 挂载到body就可以在最顶层直接控制显示隐藏
    document.body.appendChild(div)
    return new Promise((resolve: any, reject)=>{

        const close=()=>{
            div.remove();
        };

        // 封装组件属性方法
        const submitCallback = (returnObj) => {
            resolve(returnObj);
            if (successCallback) {
                successCallback();
            }
            setTimeout(close, 3000)
        }

        // 封装组件属性方法
        const cancelCallback = () => {
            //清空节点
            div.remove()
            reject()
        }

        // 在此处才创建节点并挂载属性
        const addCartApp = createApp(AddToCart, {
            submitCallback,
            cancelCallback,
            pid,
            qty,
            dom,
            div,
            successCallback
        });

        addCartApp.use(store).mount(div)
    });
}

export function batchAddToCart(pids:number[],dom, store):Promise<any>{
    // 创建一个dom节点
    const div = document.createElement('div')
    div.setAttribute('class', 'popBatch-container')
    // 挂载到body就可以在最顶层直接控制显示隐藏
    document.body.appendChild(div)

    return new Promise((resolve: any, reject)=>{
        const close=()=>{
            div.remove();
        };

        // 封装组件属性方法
        const submitCallback = (returnObj) => {
            resolve(returnObj);
            setTimeout(close, 3000)
        }

        // 封装组件属性方法
        const cancelCallback = () => {
            //清空节点
            div.remove()
            reject()
        }

        // 在此处才创建节点并挂载属性
        const batchAddApp = createApp(BatchAddToCart, {
            submitCallback,
            cancelCallback,
            pids,
            dom,
            div
        })

        // 渲染节点到指定dom
        batchAddApp.use(store).mount(div)
    });
}

export function addToReplacementCart(action: string, returnObj: SelectReplacementDto, el: HTMLElement): Promise<any> {
    return new Promise((resolve, reject) => {
        // 创建一个新的 div 容器来放置弹窗组件
        const div = document.createElement('div');
        div.setAttribute('class', 'popBatch-container');

        // 把 div 添加到 body，确保它在所有页面元素之上
        document.body.appendChild(div);

        // 定义关闭弹窗的函数
        const close = () => {
            div.remove();
            reject('Closed');
        };

        // 定义取消回调
        const cancelCallback = () => {
            div.remove();
            reject('Cancelled');
        };
        let tipMsg = '';
        if(!returnObj.message){
            tipMsg= `${action} successfully <p class="line">${returnObj.cartCount} item(s) total: <b style="color:#AF0F1E;">${(returnObj.replacementPrice-returnObj.replacementRedemptionPrice).money()}</b></p>`;
        }
        if("ERROR_PRODUCT_QTY_NOT_ENOUGH"== returnObj.message){
            if(returnObj.currentStock>0){
                let unit = "unit";
                if(returnObj.currentStock>1){
                    unit = "units";
                }
                tipMsg = `Sorry, product only have ${returnObj.currentStock} ${unit} in stock.`;
            }else {
                tipMsg = "Sorry! The item is out of stock.";
            }
        }
        // 创建组件实例
        const addToReplacementCartApp = createApp(AddToReplacementCart, {
            dom: el,  // 传递目标元素
            tipMsg:tipMsg,
            div,
            cancelCallback,
        });

        // 挂载组件到 div 元素
        addToReplacementCartApp.mount(div);

        // 等待 Vue 更新完成后再进行其他操作
        nextTick(() => {
            // @ts-ignore
            resolve();
        });
    });
}
