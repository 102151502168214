import useUserStore from "@/store/user"
import * as Sentry from "@sentry/vue"
import {useContext} from "./Ph";
export function  errorHandler(err:any, vm, info) {
    let context = useContext();
    let loginUrl = "/Customer/SignIn";
    if(err && err.response && (err.response.data['err_code'] == "518" || err.response.status == '518')){
        if(context.isClient){
            window.location.href=loginUrl;
            return;
        }else {
            context.response.writeHead(301, { Location: loginUrl });
            context.response.end();
            return;
        }
    }
    // 获取用户状态管理实例
    const userStore = useUserStore();
    // 设置系统错误标志
    userStore.systemError = true;
    Sentry.setUser({
        username: userStore.headerDto.firstName + userStore.headerDto.lastName,
    });

    Sentry.captureException(err);

    Sentry.setContext('error_info', {
        component: vm.$options.name,
        info: info,
    });

    // 检查是否在浏览器环境
    if (typeof window !== 'undefined') {
        // 构造日志对象
        const log = {
            stack: err.stack,
            info: info,
            url: window.location.href
        };

        // 发送日志到服务器
        sendLogToServer(log);
    }
}

export function sendLogToServer(log) {
        fetch('/client/logs', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(log),
        }).catch(err => {
            console.error('Failed to send log to server:', err);
        });
}