import {nextTick, onBeforeMount, onMounted, reactive, ref, toRefs} from 'vue';
import WishListDirDto from "@/service/model/myaccount/WishListDirDto";
import {wishListController} from "@/service/WishListController";
import useUserStore from "@/store/user";
import AddToWishListDto from "@/service/model/myaccount/AddToWishListDto";
import popLogin from "@/components/common/popLogin";
import {getScreen, PhScreen} from "@/utils/media";
import {cartController} from "@/service/CartController";
import {itegtm} from "@/utils/gtm";
export default {
    name: "AddToWishList",
    props: {
        pids: Array,
        excludeDirId:Number,
        dom: String,
        div: String,
        submitCallback: Function,
        cancelCallback: Function,
        actionType: { // 根据页面或操作传递的标识，用于区分不同操作
            type: String,
            default: 'default'
        },
        successCallback: Function, // 新增回调，用于处理父组件的自定义逻辑
        cartId: { // 可选参数，如果是购物车操作需要传递
            type: Number,
            default: null
        },
        productIds:Array,
        qty:Array,
    },
    setup(props, content) {
        const fade = ref(false)
        const store = useUserStore()
        const data = reactive({
            // wishlist dir 结果集
            wishList: <WishListDirDto[]> [],
            // 默认dir  {name, id}
            defalutListObj: <any> {},
            // input
            createListDirName: <string> '',
            // 创建wistlist dir 失败提示
            createErrorMsg: <string> '',
            // 是否展示dir
            isShowWishListDir: <boolean> false
        })
        // 点击事件处理函数
        const handleOutsideClick = (event) => {
            const addListElement = document.querySelector('.addList');

            if (addListElement && !addListElement.contains(event.target) ) {
                try{
                    props.cancelCallback()
                }catch (e){
                    console.error('Error in cancelCallback:', e)
                }

            }
        };

        onMounted(async () => {
            try {
                data.wishList = await wishListController.wishListDirList(props.excludeDirId);
                data.defalutListObj = data.wishList.filter(a => a.updateLatest)[0];

                setPosition(props.dom, props.div);
                setTimeout(() => {
                    fade.value = true;
                }, 0);

                // 监听点击事件
                document.addEventListener('click', handleOutsideClick);
            } catch (e) {
                popLogin().then(()=>{window.location.reload()}, () => {}).catch(props.cancelCallback);
            }
        });

        onBeforeMount(() => {
            // 移除事件监听
            document.removeEventListener('click', handleOutsideClick);
        });

        // 添加一个wishlist目录
        const createListDir = async () => {
            try {
                await wishListController.createWishListDir(data.createListDirName,'')

                // 查询wishlist
                data.wishList = await wishListController.wishListDirList(props.excludeDirId)

            }catch (e) {
                let errData = e.response.data;
                if(errData.err_code=="510") {
                    if (errData.message == "WISHLIST_NAME_REPEAT") {
                        data.createErrorMsg = 'Sorry! A list with this name already exists.'
                    }
                }
            }

        }

        // 切换选择dir
        const chooseDir = (item) => {
            data.defalutListObj = item
            // 下拉列表收起
            data.isShowWishListDir = false
        }

        // 加入wish list
        const addToWishList = async () => {
            let cartGa4ListName = 'Cart';
            let cartGa4ListId = 'C003';
            let returnObj: AddToWishListDto;
            // 根据 actionType 执行不同的操作
            switch (props.actionType) {
                case 'cartSingleMoveAction':
                    returnObj = await cartController.movetoWishList(props.pids,props.cartId, data.defalutListObj.id);
                    itegtm.addToWishList(props.productIds,cartGa4ListName,cartGa4ListId);
                    itegtm.removeFromCart(props.productIds,cartGa4ListName,cartGa4ListId,props.qty);
                    break;
                case 'cartBatchMoveAction':
                    returnObj = await cartController.batchCartMovetoWishList(props.pids, data.defalutListObj.id);
                    itegtm.addToWishList(props.productIds,cartGa4ListName,cartGa4ListId);
                    itegtm.removeFromCart(props.productIds,cartGa4ListName,cartGa4ListId,props.qty);
                    break;
                case 'default' || 'hoverAdd':
                default:
                    returnObj = await wishListController.addToWishList(props.pids, data.defalutListObj.id);
                    itegtm.addToWishList(props.pids,'','');
                    break;
            }
            // 判断是否需要登录
            if (returnObj.needLogin) {
                popLogin()
                    .then(addToWishList, () => {})
                    .catch(props.cancelCallback);
            } else {
                // 更新 wishlist 数量
                store.updateHeadWishList(returnObj.count);
                if(props.actionType == 'default') {
                    // 更新 DOM 处理
                    if (window.location.href.indexOf('/p-') > 0 || window.location.href.indexOf('/Replacement') > 0
                        || (returnObj.mode === 0 && getScreen() != PhScreen.PHONE_SMALL && getScreen() != PhScreen.PHONE )
                    ) {
                        props.dom.innerHTML = 'View My Wish List';
                    } else {
                        props.dom.setAttribute('class', 'Collect addToWishListBtn ViewMyWishList');
                    }
                    props.dom.setAttribute('id', data.defalutListObj.id);
                }

                if(props.actionType == 'hoverAdd') {
                    props.dom.setAttribute('class', 'Collect addToWishListBtn ViewMyWishList');
                    props.dom.setAttribute('id', data.defalutListObj.id);
                }

                // 默认的回调函数
                props.submitCallback();
                // 自定义成功回调
                if (props.successCallback && typeof props.successCallback === 'function') {
                    props.successCallback(returnObj);
                }
            }
        }

        // add to wishList 弹窗位置
        const setPosition = async (clickWrap, popWrap) => {
            await nextTick()
            if(getScreen()!= PhScreen.PHONE && getScreen()!= PhScreen.PHONE_SMALL) {
                let {width, height, top, left} = clickWrap.getBoundingClientRect()
                popWrap.style.position = "absolute"
                popWrap.style.zIndex = 99
                popWrap.style.top = top  + height + window.scrollY + 6 + "px"
                popWrap.style.left = left + window.scrollX - ((popWrap.clientWidth- clickWrap.clientWidth)/2) + "px"
            } else {
                popWrap.style.position = "fixed"
                popWrap.style.zIndex = 99
                popWrap.style.top = '50%'
                popWrap.style.left = '25%'
            }
        }


        return {
            fade,
            createListDir,
            chooseDir,
            addToWishList,
            ...toRefs(data)
        }
    }
}